<template>
  <div style="background-color: #f7f7f7;height: 100vh;">
    <div class="title_div">
      <div
        style="
          height: 6vh;
          line-height: 12vh;
          font-size: 4.6vw;
          display: flex;
          justify-content: center;
        "
      >
        <div>门店处方</div>
      </div>
    </div>
    <!--时间-->
    <div style="display: flex; justify-content: center; margin-top: 1vh">
      <div
        style="
          display: flex;
          height: 6vh;
          align-items: center;
          background-color: #ffffff;
          border-radius: 7px;
        "
      >
        <div
          v-for="(item, index) in time_item"
          :key="index"
          class="every_time"
          @click="chooseTime(index)"
          :style="
            index < 3 ? 'border-right: 1px solid rgba(204, 204, 204, 0.3);' : ''
          "
        >
          <img
            v-if="time_value != index"
            :src="item.icon"
            style="height: 5.2vw; width: 5.2vw"
          />
          <img
            v-else
            :src="item.icon_xuan"
            style="height: 5.2vw; width: 5.2vw"
          />
          <div
            class="time_choose"
            :style="time_value == index ? 'color:#2163E5;' : ''"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div> 
   
          <!--中医绩效-->
          <div
      style="
        display: flex;
        margin-top: 1.6vh;
        align-items: center;
        margin-left: 5vw;
        margin-bottom: 1vh;
      "
    >
      <img src="@/assets/icon/xiaoshouhuizong.png" class="option_img" />
      <div class="option_title">门店处方</div>
    </div>
    <div class="main" >
  <div class="mainInfo" >
   
    <el-table
    :header-cell-style="{background: '#fff',padding:'0',color:'#000','text-align':'center',height:'35px'}"
    :data="getPerformanceList"
    style="border-radius:3vw;color:#7A7A7A;font-size: 13px;"
    :row-class-name="tableRowClassName"
    :row-style="{height:'30px'}"
    :cell-style="{padding:'4px'}"
    :sort-method="(a, b) => sortMethod(num, a, b,amount,a,b,profitRatio,a,b)"
   
    >
    <el-table-column 
      prop="storeName"
      label="门店名称"
      width='80'
      align="center"
      >
    
    </el-table-column>
    <el-table-column
      prop="num"
      label="方数"
      width='70'
      align="center"
      sortable
      >
    </el-table-column>
    <el-table-column
      prop="amount"
      label="方金额"
      width='86'
      align="center"
      sortable
      >
    </el-table-column>
    <el-table-column
      prop="profitRatio"
      label="毛利率"
      width='85'
      align="center"
      sortable
      >
    </el-table-column>
    <el-table-column  label="操作" width="50"  align="center">
          <template slot-scope="scope">
            <el-button @click="checkOut(scope.row)" type="text" size="small" >查看</el-button>
          
          </template>
        </el-table-column>
  </el-table>
  </div>
 </div>  
    <!--底部tab栏-->
    <Tabbar
      :choose_index="tab_type"
      :type="pagetype"
      :user_type2="user_type2"
    ></Tabbar>
  </div>
</template>

<script>
import LineChart5 from "@/components/LineChart5";
import Tabbar from "@/components/Tabbar";
import { getHisData } from "../api";
export default {
  components: {
    LineChart5,
    Tabbar,
  },
  data() {
    return {
      show_tab: true,
      binYList:[
        {name:"冯某某",hsf:6,jc:150,zlyp:240,合计:396},
        {name:"赵某某",hsf:6,jc:300,zlyp:500,合计:806},
        {name:"周某某",hsf:6,jc:150,zlyp:240,合计:396}
      ],
      kfList:[
        { name:'赵永权',a1:1000,a2:2000,a3:1000,a4:2000,total:60000},
        { name:'李涛涛',a1:100,a2:200,a3:100,a4:100,total:500},
        { name:'赵永权',a1:100,a2:200,a3:100,a4:200,total:600},
        { name:'李涛涛',a1:100,a2:200,a3:100,a4:100,total:500}
      ],
      tableData3: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
      ],
      time_item: [
        {
          name: "今日",
          icon: require("@/assets/icon/shishi.png"),
          icon_xuan: require("@/assets/icon/shishi_xuan.png"),
          value: 0,
        },
        {
          name: "昨日",
          icon: require("@/assets/icon/zuori.png"),
          icon_xuan: require("@/assets/icon/zuori_xuan.png"),
          value: 1,
        },
        {
          name: "一周",
          icon: require("@/assets/icon/yizhou.png"),
          icon_xuan: require("@/assets/icon/yizhou_xuan.png"),
          value: 2,
        },
        {
          name: "当月",
          icon: require("@/assets/icon/dangyue.png"),
          icon_xuan: require("@/assets/icon/dangyue_xuan.png"),
          value: 3,
        },
      ], //时间队列
      pagetype: 0, //1 为总部看板 2为门店看板
      tab_type: 0, //tab栏
      startTime: "", //开始时间
      endTime: "", //结束时间
      table_data1: [], //数据体1
      table_data2: [], //数据体2
      table_data3: [], //数据体3
      table_data4: [], //数据体4 电商排名
      line_data: [], //折现统计图
      time_value: 0, //默认显示实时
      img_list: [
        require("@/assets/icon/hanshui.png"),
        require("@/assets/icon/xin.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/keliu.png"),
        require("@/assets/icon/huiyuan.png"),
        require("@/assets/icon/yingye.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/hanshuimao.png"),
        require("@/assets/icon/keliuliang.png"),
        require("@/assets/icon/kedanjia.png"),
        require("@/assets/icon/zongmaoli.png"),
        require("@/assets/icon/chengben.png"),
        require("@/assets/icon/chengben.png"),
        require("@/assets/icon/chengben.png"),
      ],
      menu_list1: [], //菜单1
      esampleLisdt:[],
      // menu_list2:[],//菜单2
      loading: "", //loading
      new_show_date: "", //日期
      user_type2: false, //区域账号
      type2shopId: "", //区域id
      eight: "",
     

      shList: [
        {
          name1: "李某某",
          time: 2023.7,
          sf: 4511,
          carSf: 2000,
          jcSf: 600,
          heji: 6100,
        },
        {
          name1: "冯某某",
          time: 2023.7,
          sf: 4500,
          carSf: 1000,
          jcSf: 600,
          heji: 6100,
        },
        
      ],
      getChineseRecipeList:[],
        getPerformanceList:[],
      filters: [
        { name: "挂号人数", data:80,img: require("@/assets/icon/guahao.png"), },
        { name: "检查人数", data:50,img: require("@/assets/icon/jiancha.png"),  },
        { name: "检查费用", data:3000,img: require("@/assets/icon/jianchafy.png"),},
        { name: "中医开方", data:6000,img: require("@/assets/icon/zykf.png"),  },
        { name: "销售金额", data:10000,img: require("@/assets/icon/money.png"),},
      ],
    };
  },
  watch: {
    // $route(){
    //     this.pagetype = this.$route.query.pagetype*1;//切换总部看板 与 门店看板
    //     this.curve_api();
    //     this.viewmenu_api();
    // }
  },
  mounted() {
    this.getHisData()
    this.init();
    this.$axios.post(this.$api.user_api).then((res) => {
      console.log(res, "用户信息");

      // localStorage.setItem("user_info", JSON.stringify(res.data.data));

      this.userType = res.data.data.type;
      if (this.userType == 0) {
        this.pagetype = 1;
      } else if (this.userType == 2) {
        this.user_type2 = true;
      } else {
        this.pagetype = 2;
      }
      this.shopId = res.data.data.shopId;
    });
    // this.eight = JSON.parse(localStorage.getItem("user_info")).type;
    // console.log(this.eight, "aaa");
    if (localStorage.getItem("user_info") != "") {
      this.tab_type = this.$route.query.pagetype * 1;
      // console.log(this.tab_type, "o900909090-0-9");
      // this.pagetype = this.$route.query.pagetype*1;//切换总部看板 与 门店看板
      if (
        JSON.parse(localStorage.getItem("user_info")).type == 0 ||
        JSON.parse(localStorage.getItem("user_info")).type == 8
      ) {
        //总部看板
        this.pagetype = 1;
      } else {
        //门店看板
        this.pagetype = 2;
      }
      //区域看板
      if (
        JSON.parse(localStorage.getItem("user_info")).type == 2 ||
        JSON.parse(localStorage.getItem("user_info")).type == 4 ||
        JSON.parse(localStorage.getItem("user_info")).type == 6
      ) {
        //2为区域账号
        this.user_type2 = true;
        // this.getShopByArea();
      } else {
        console.log("111111111");
        // this.init();
      }
    }
  },
  methods: {
    checkOut(row){
      this.$router.push(
      {name:"service6",params:
      {pagetype:row.storeNo, endTime:this.endTime,startTime:this.startTime,}}
      
      );
console.log(row,"门店处方查看")
    },
    tableRowClassName({row,rowIndex} ) {
      if ((rowIndex + 1) % 2 === 0) {
        return "oddRow";
      }
      return "evenRow";
    },
    getHisData(){
      this.$axios
        .get(this.$api.getHisData)
        .then((res) => {
          console.log(res.data.data,"")
          this.line_data=res.data.data
        });
},

    //初始化
    // 跳转到值班收费明细
    table_on(val) {
      console.log("检查收费明细", val);
      let a="单鹏飞"
      let b='2023.07.26'
      this.$router.push(`./jcPage?name=${a}&time=${b}`);
    },
    //跳转到车队收费明细
    car_on(val) {
      let a="单鹏飞"
      let b='2023.07.26'
      console.log("诊疗/药品收费明细", val);
      this.$router.push(`./zlypPage?name=${a}&time=${b}`);
    },
    init() {
      this.getShowDate(); //格式化日期
      // this.showLoading();
      this.endTime = this.getYYYYMMDD();
      this.startTime = this.getYYYYMMDD();
      this.time_value = 0;
      this.getChineseRecipe()
      this.getPerformance()
    },
    feeRegistration(){
      this.$axios
        .post(this.$api.feeRegistration, {
          endTime:this.endTime,
          startTime:this.startTime,
          userType:11
        })
        .then((res) => {
          console.log(res.data.data,"")
          // this.binYList=res.data.data
          // this.type2shopId = res.data.data.shopId;
          // this.init();
        });
    },
    getDataComparison(){
      this.$axios
        .post(this.$api.getDataComparison, {
          endTime:this.endTime,
          startTime:this.startTime,
          userType:11
        })
        .then((res) => {
          console.log(res,"")
          this.esampleLisdt=res.data.data
    //       let sum1 = 0;
    // let sum2 = 0;
    // let sum3 = 0;
    // let sum4 = 0;
    // this.esampleLisdt.forEach((ele) => {
    //   sum1 = sum1 + ele.singleNonFleetFee;
    //   sum2 = sum2 + ele.singleFleetFee;
    //   sum3 = sum3 + ele.doubleNonFleetFee;
    //   sum4 = sum4 + ele.doubleFleetFee;
    // });
    // this.esampleLisdt.push({
    //   typeName: "合计",
    //   singleNonFleetFee: sum1,
    //   singleFleetFee: sum2,
    //   doubleNonFleetFee: sum3,
    //   doubleFleetFee: sum4,
    // });
          // this.type2shopId = res.data.data.shopId;
          // this.init();
        });
    },
    //获取区域详情
    getShopByArea() {
      this.$axios
        .post(this.$api.getShopByArea, {
          area: JSON.parse(localStorage.getItem("user_info")).shopId,
        })
        .then((res) => {
          this.type2shopId = res.data.data.shopId;
          this.init();
        });
    },
    //获取显示日期
    getShowDate() {
      this.new_show_date = this.getYYYYMMDD();
      // let new_date = new Date(Date.now());
      // let new_tomorrow = new Date(Date.now() - 86400000);
      // this.time_item[0].name = new_date.getDate() + '号';
      // this.time_item[1].name = new_tomorrow.getDate() + '号';
    },
    //loading
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //时间格式化
    getYYYYMMDD(e) {
      let nowDate = "";
      if (e) {
        nowDate = new Date(e);
      } else {
        nowDate = new Date();
      }
      let day = nowDate.getDate();
      let month = nowDate.getMonth() + 1;
      let year = nowDate.getFullYear();
      return year + "-" + month + "-" + day;
    },
    //选择时间
    chooseTime(e) {
      // this.showLoading();
      this.time_value = e;
      if (e == 0) {
        //实时
        this.startTime = this.getYYYYMMDD();
        this.endTime = this.getYYYYMMDD();
      } else if (e == 1) {
        //昨日
        this.startTime = this.getYYYYMMDD(Date.now() - 86400000);
        this.endTime = this.getYYYYMMDD(Date.now() - 86400000);
      } else if (e == 2) {
        //一周
        this.startTime = this.getYYYYMMDD(Date.now() - 518400000);
        this.endTime = this.getYYYYMMDD();
      } else if (e == 3) {
        //当月
        let nowDate = new Date();
        let month = nowDate.getMonth() + 1;
        let year = nowDate.getFullYear();
        this.startTime = year + "-" + month + "-1";
        this.endTime = this.getYYYYMMDD();
      }
      this.getChineseRecipe()
      this.getPerformance()
      // this.curve_api();
      // this.curve2_api();
      // this.viewmenu_api();
      // this.feeRegistration()
      // this.getDataComparison()
    },
    //查询中医开方列表
    getChineseRecipe(){
      this.$axios
        .post(this.$api.getChineseRecipe, {
          endTime:this.endTime,
          startTime:this.startTime,
          // endTime:"2023-09-15",
          // startTime:"2023-09-15",
        })
        .then((res) => {
          console.log(res.data.data,"中医开方列表")
          this.getChineseRecipeList=res.data.data
          // this.binYList=res.data.data
          // this.type2shopId = res.data.data.shopId;
          // this.init();
        });
    },
    //查询中医绩效列表
    getPerformance(){
      this.$axios
        .post(this.$api.getPrescriptionData, {
          endTime:this.endTime,
          startTime:this.startTime,
          // endTime:"2023-09-15",
          // startTime:"2023-09-15",
        })
        .then((res) => {
       let amount=0
       let fs=0
          this.getPerformanceList=res.data.data
          this.getPerformanceList.map(ele=>{
            amount=amount+ele.num
            fs=fs+ele.amount
         if(ele.storeName.includes('db')){
          var str1=ele.storeName.substring(0, ele.storeName.indexOf("db"));
            ele.storeName=str1
          
            
console.log(str1) 
         }

        

          })
          fs=fs.toFixed(2)
        
          this.getPerformanceList.push({
            storeNameL:'总计',
            num:amount,
            amount:fs
          })

          // this.binYList=res.data.data
          // this.type2shopId = res.data.data.shopId;
          // this.init();
        });
    },
    //获取电商排名
    curve2_api() {
      this.$axios
        .post(this.$api.curve2_api, {
          shopId: this.user_type2
            ? this.type2shopId
            : JSON.parse(localStorage.getItem("user_info")).userId,
          startTime: this.startTime,
          endTime: this.endTime,
          userType: JSON.parse(localStorage.getItem("user_info")).type,
        })
        .then((res) => {
          // console.log( res.data.data[1], "1111111111");
          if (res.data.data != "") {
            this.table_data4 = [];
            if (res.data.data[1] == undefined || res.data.data[1] == "") {
              res.data.data[1] = [];
            }
            if (res.data.data[0] == undefined || res.data.data[0] == "") {
              res.data.data[0] = [];
            }
            this.table_data4 = this.table_data4.concat(
              res.data.data[0],
              res.data.data[1]
            );
            // console.log(res, "电商");
            //对table_data4进行排序
            for (let i = 0; i < this.table_data4.length; i++) {
              for (let j = 0; j < this.table_data4.length; j++) {
                // console.log(this.table_data4.length[j])
                // console.log(this.table_data4.length[j + 1])
                if (
                  this.table_data4.length[j] < this.table_data4.length[j + 1]
                ) {
                  let tmp = this.table_data4.length[j];
                  this.table_data4.length[j] = this.table_data4.length[j + 1];
                  this.table_data4.length[j + 1] = tmp;
                }
              }
            }
            //计算总价
            let total = {
              门店名称: "合计",
              销售额: 0,
              成本额: 0,
              毛利额: 0,
              成本价: 0,
            };
            for (let i = 0; i < this.table_data4.length; i++) {
              total.销售额 = total.销售额 + this.table_data4[i].销售额 * 1;
              total.成本额 = total.成本额 + this.table_data4[i].成本额 * 1;
              total.毛利额 = total.毛利额 + this.table_data4[i].毛利额 * 1;
              total.成本价 = total.成本价 + this.table_data4[i].成本价 * 1;
            }
            total.销售额 = total.销售额.toFixed(2) * 1;
            total.成本额 = total.成本额.toFixed(2) * 1;
            total.毛利额 = total.毛利额.toFixed(2) * 1;
            total.成本价 = total.成本价.toFixed(2) * 1;
            this.table_data4.push(total);
          }
        });
    },
    //获取曲线及销售排名数据
    curve_api() {
      let url = this.$api.curve_api;
      let params = {
        userId: JSON.parse(localStorage.getItem("user_info")).userId,
        userType: JSON.parse(localStorage.getItem("user_info")).type,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      if (
        JSON.parse(localStorage.getItem("user_info")).type != 0 &&
        JSON.parse(localStorage.getItem("user_info")).type != 8
      ) {
        //0为总部看板
        url = this.$api.curve_api + "1";
        if (this.user_type2) {
          //区域id
          params.shopId = this.type2shopId;
        } else {
          params.shopId = JSON.parse(localStorage.getItem("user_info")).shopId;
        }
      }

      this.$axios.post(url, params).then((res) => {
        // console.log(res, "a");
        this.loading.close();
        console.log(this.table_data3, "销售额");
        this.table_data3 = res.data.data[0];
        //计算合计数据
        if (this.pagetype == 1) {
          //1总店看板
          let total = {
            销售额: 0,
            毛利额: 0,
            毛利率: 0,
            gk毛利: 0,
            gk毛利率: 0,
            分支机构: "合计",
            分支机构编码: "合计",
          };
          // console.log(this.table_data3, "3");
          if (this.table_data3 != null) {
            for (let i = 0; i < this.table_data3.length; i++) {
              this.table_data3[i].分支机构编码 = this.table_data3[i].分支机构;
              //去掉前五位
              // console.log(this.table_data3[i].分支机构,"分支机构")
              this.table_data3[i].分支机构 = this.table_data3[
                i
              ].分支机构.substring(6, this.table_data3[i].分支机构.length);
              // console.log(this.table_data3[i].分支机构,"截取")
              total.销售额 = total.销售额 * 1 + this.table_data3[i].销售额 * 1;
              total.毛利额 = (
                total.毛利额 * 1 +
                this.table_data3[i].毛利额 * 1
              ).toFixed(1);
              total.毛利率 = (
                total.毛利率 * 1 +
                this.table_data3[i].毛利率 * 1
              ).toFixed(1);
              total.gk毛利 = (
                total.gk毛利 * 1 +
                this.table_data3[i].gk毛利 * 1
              ).toFixed(1);
              total.gk毛利率 = (
                total.gk毛利率 * 1 +
                this.table_data3[i].gk毛利率 * 1
              ).toFixed(1);
            }
          }

          // console.log(this.table_data3, "33333");
          total.销售额 = total.销售额.toFixed(1);
          this.line_data = this.table_data3; //折线图
          console.log(total, "平均");
          console.log(total.毛利率, "毛利率");
          console.log(total.gk毛利, "gk毛利");
          console.log(total.销售额, "销售额");
          console.log(total.毛利率 / total.销售额, "折线");

          total.毛利率 = ((total.毛利额 / total.销售额) * 100).toFixed(2) + "%";
          // total.毛利率=(total.毛利率*100)+"%"
          total.gk毛利率 =
            ((total.gk毛利 / total.销售额) * 100).toFixed(2) + "%";
          // total.gk毛利率=(total.gk毛利率*100)+"%"
          if (this.table_data3 != null) {
            this.table_data3.push(total); //带上总计
          }

          console.log(this.table_data3, "合计");
        } else {
          //2门店看板
          let total = {
            销售额: 0,
            营业员: "合计",
            会员销售额: 0,
            客单价: 0,
            客流量: 0,
          };
          // console.log(this.table_data3, "4");

          if (this.table_data3 != null) {
            for (let i = 0; i < this.table_data3.length; i++) {
              this.table_data3[i].营业员 = this.table_data3[i].营业员;
              total.销售额 = total.销售额 * 1 + this.table_data3[i].销售额 * 1;
              total.会员销售额 =
                total.会员销售额 * 1 + this.table_data3[i].会员销售额 * 1;
              total.客单价 = total.客单价 * 1 + this.table_data3[i].客单价 * 1;
              total.客流量 = total.客流量 * 1 + this.table_data3[i].客流量 * 1;
            }
          }
          total.销售额 = total.销售额.toFixed(2) * 1;
          total.会员销售额 = total.会员销售额.toFixed(2) * 1;
          total.客单价 = total.客单价.toFixed(2) * 1;
          total.客流量 = total.客流量.toFixed(2) * 1;
          this.line_data = this.table_data3; //折线图
          // console.log(this.line_data, "折线111");
          if (this.table_data3 != null) {
            this.table_data3.push(total); //带上总计
          }
        }
      });
    },
    //获取看板名字
    viewmenu_api() {
      this.$axios
        .post(this.$api.viewmenu_api, {
          userType: JSON.parse(localStorage.getItem("user_info")).type,
        })
        .then((res) => {
          //格式化菜单
          let menu_data = res.data.data;
          this.menu_list1 = [];
          // this.menu_list2 = [];
          let every_item = {};
          // for(let i=0;i<4;i++){
          //   every_item = {
          //     name:menu_data[i].view,
          //     sql: menu_data[i].sql
          //   }
          //   this.menu_list1.push(every_item)
          // }
          // for(let i=0;i<5;i++){
          //   every_item = {
          //     name:menu_data[i].view,
          //     sql: menu_data[i].sql
          //   }
          //   this.menu_list2.push(every_item)
          // }
          for (let i = 0; i < menu_data.length; i++) {
            every_item = {
              name: menu_data[i].view,
              sql: menu_data[i].sql,
            };
            this.menu_list1.push(every_item);
          }
          this.view1_api();
        });
    },
    //获取看板数据
    view1_api() {
      this.table_data1 = [];
      this.table_data2 = [];
      // console.log(this.menu_list1,"11")
      for (let i = 0; i < this.menu_list1.length; i++) {
        if (this.menu_list1[i].name == "毛利率") {
          this.$delete(this.menu_list1, i);
        }
        console.log(this.menu_list1[i], "this.menu_list1");
        let query = {
          userId: JSON.parse(localStorage.getItem("user_info")).userId,
          userType: JSON.parse(localStorage.getItem("user_info")).type,
          shopId: this.user_type2
            ? this.type2shopId
            : JSON.parse(localStorage.getItem("user_info")).shopId,
          startTime: this.startTime,
          endTime: this.endTime,
          sql: this.menu_list1[i].sql,
        };
        this.$axios.post(this.$api.view1_api + (i + 1), query).then((res) => {
          console.log(res.data.data, "中科中医院");
          let every_data = res.data.data;
          if (!every_data.length) {
            // console.log("g");
            return false;
          }
     

          for (let k = 0; k < this.menu_list1.length; k++) {
            //  console.log(every_data[0],"every_data[0]")
            if (every_data[0] != null) {
              if (this.menu_list1[k].name == Object.keys(every_data[0])[0]) {
                let every_item = {
                  name: Object.keys(every_data[0])[0],
                  value: Object.values(every_data[0])[0],
                  
                };

                if (k < 5) {
                  this.table_data1[k] = every_item;
                } else {
                  this.table_data2[k - 2] = every_item;
   
                }
                this.table_data1.map(ele=>{
                  if(ele.name=="挂号人数"){
                    ele.img=require("@/assets/icon/guahao.png")
                  }
                  if(ele.name=="检查人数"){
                    ele.img=require("@/assets/icon/jiancha.png")
                  }
                  if(ele.name=="检查费用"){
                    ele.img=require("@/assets/icon/jianchafy.png")
                  }
                  if(ele.name=="中医开方"){
                    ele.img=require("@/assets/icon/zykf.png")
                  }
                  if(ele.name=="销售金额"){
                    ele.img=require("@/assets/icon/money.png")
                  }
                })
                this.table_data1.push(",");
                this.table_data1.pop();
                // console.log(this.table_data1,"this.table_data1")
              }
            } 
          }
          console.log(this.table_data1,"111111111");
        });
      }
    },
  },
};
</script>
<style scoped>
table {
  text-align: center;
}

/* td {
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .container {
  display: flex;
  align-items: center;
} */
table {
  margin: 0 auto;
}
.title_div {
  height: 9vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  /* line-height:12vh;
  text-align: center;
  font-size:4.6vw; */
}

.every_option1 {
  height: 8vh;
  width:18.6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  /* position: relative; */
  

}
.every_time {
  height: 5vh;
  width: 23vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option_img {
  height: 5vw;
  width: 5vw;
  margin-right: 1.5vw;
}
.option_title {
  font-size: 4vw;
  font-weight: 800;
  color: #000000;
}
.every_option2 {
  /* width: 30vw;

  background-color: #ffffff;
  margin-top: 0.8vh; */
  border-radius: 9px;
  height: 9vh;
  width: 44.7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
  margin-top: 1.5vw;
}
.every_optionsF {
  width: 91.5vw;

  background-color: #ffffff;
  margin-top: 0.8vh;
  border-radius: 10px;
}
.every_optionsF1 {
  width: 91.5vw;

  background-color: #ffffff;
  margin-top: 0.8vh;
  border-radius: 10px;
}
.option2_font {
  height: 4vh;
  line-height: 5vh;
  /* font-size:1vw; */
  transform: scale(0.8);
  width: 34vw;
  margin-left: -2vw;
  color: #898989;
}
.option2_font2 {
  font-size: 3vw;
  color: #898989;
  margin-left: 1vw;
}
.ranking_icon {
  height: 3.6vw;
  width: 4vw;
}
.time_choose {
  height: 5.4vw;
  line-height: 5.4vw;
  font-size: 3.6vw;
  margin-left: 1.6vw;
}
.line_body {
  height: 30vh;
  background-color: #ffffff;
  margin-left: 2.5vw;
  border-radius: 7px;
  /* width:600px; */
  /* overflow: scroll; */
}
.el-table thead.is-group th {
  background: #fff;
}

.el-table thead.is-group tr:first-of-type th:first-of-type:before {
  content: "1";
  text-align: center;
  position: absolute;
  width: 152px;
  height: 1px;
  bottom: 30px;
  right: 0;
}

.el-table thead.is-group tr:first-of-type th:first-of-type:after {
  content: "2";
  text-align: center;
  position: absolute;
  width: 152px;
  top: 10px;
  left: 0;
}

.el-table thead.is-group tr:first-of-type th:first-of-type .cell {
  position: absolute;
  top: 0;
  left: 0;
  width: 152px;
  height: 1px;
  background-color: #7891c9;
  display: block;
  text-align: center;
  transform: rotate(38deg);
  transform-origin: top left;
  -ms-transform: rotate(38deg);
  -ms-transform-origin: top left;
  -webkit-transform: rotate(38deg);
  -webkit-transform-origin: top left;
}
.dic{
  border-bottom:1px solid #999;
  transform: rotate(25deg);
}
.red{
  background:#F6F6F6;
}
.blue{
  background: #fff;
}
.main{
  width: 100%;
 height: auto;
 /* background: green; */
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 2vw;
}
.mainInfo{
   width: 94vw;
  height: auto;
  overflow: scroll;
  /* background:  purple; */
  /* border-radius: 3vw; */
  /* border: none; */
  
  
}


</style>
<style>
.el-table .oddRow {
 background: #ffffff!important;
}

.el-table .evenRow {
background: #F7F7F7!important;
}
</style>
